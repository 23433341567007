import { FareMap } from '@/models/FareClass'

export interface Fares {
  adult?: number;
  child?: number;
  youngAdult?: number;
  student?: number;
  seatReservation?: number;
  dsp?: number;
  [x: string]: number | undefined;
}

export const urlEncodeFares = (fares: Fares) =>
  Object.keys(fares)
    .filter(key => fares[key])
    .map(key => `${key},${fares[key]}`)
    .join(';')

export const gatewayEncodeFares = (fares: Fares) =>
  Object.keys(fares)
    .filter(key => fares[key])
    .map(key => `${FareMap[key]},${fares[key]}`)
    .join(';')

export const decodeFares = (str: string): Fares =>
  str.split(';').reduce((acc, substr) => {
    const [key, value] = substr.split(',')
    acc[key] = Number(value)
    return acc
  }, {} as { [x: string]: number })

export const passengersNumber = (fares: Fares): number => {
  let passengers = 0

  for (const el in fares) {
    passengers += fares[el] || 0
  }

  return passengers
}

export const fareToHuman = (fare: string): string => {
  const humanizedFare = fare.split('.')[1].toLocaleLowerCase()

  return humanizedFare.charAt(0).toUpperCase() + humanizedFare.slice(1)
}


import { defineComponent, ref, computed, toRefs, PropType } from 'vue'

export default defineComponent({
  props: {
    options: {
      type: Array as PropType<Record<string, unknown>[]>,
      default: () => []
    },
    label: String,
    id: String,
    filterBy: {
      type: Function,
      required: true
    },
    valueBy: {
      type: Function
    },
    searchTerm: {
      type: String,
      required: true
    },
    selection: {
      type: Object
    }
  },
  emits: ['update:selection', 'update:searchTerm', 'close'],
  setup (props, { emit }) {
    const { options, filterBy, searchTerm } = toRefs(props)
    const valueBy = props.valueBy ? ref(props.valueBy) : ref(filterBy.value)
    const input = ref<HTMLInputElement>()
    const showListbox = ref(false)
    const keyboardFocus = ref<null | number>(null)

    const close = () => {
      showListbox.value = false
      emit('close')
    }

    const filterRegex = computed(
      () =>
        new RegExp(
          `${searchTerm.value.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') || '.*'}`,
          'i'
        )
    )

    const filteredOptions = computed(() =>
      options.value.filter((option: Record<string, unknown>) =>
        filterRegex.value.test(filterBy.value(option))
      )
    )

    const updateSearchTerm = (searchTerm: string) => {
      keyboardFocus.value = null
      emit('update:searchTerm', searchTerm)
    }

    const onSelect = (option: Record<string, unknown>) => {
      emit('update:selection', option)
      updateSearchTerm(valueBy.value(option))
      input.value?.blur()
      input
    }

    const onDownArrow = () => {
      if (!showListbox.value) return

      if (keyboardFocus.value === null) keyboardFocus.value = 0
      else {
        keyboardFocus.value =
          (keyboardFocus.value + 1) % filteredOptions.value.length
      }
    }

    const onUpArrow = () => {
      if (!showListbox.value) return

      const optionCount = filteredOptions.value.length
      if (keyboardFocus.value === null) keyboardFocus.value = optionCount - 1
      else {
        keyboardFocus.value =
          (keyboardFocus.value - 1 + optionCount) % optionCount
      }
    }

    const onEnter = () => {
      if (keyboardFocus.value === null) return

      onSelect(filteredOptions.value[keyboardFocus.value])
    }

    const activeDescendantRef = computed(() =>
      keyboardFocus.value === null
        ? null
        : `${props.id}-result-${keyboardFocus.value}`
    )

    return {
      input,
      filteredOptions,
      showListbox,
      onSelect,
      onDownArrow,
      onUpArrow,
      onEnter,
      updateSearchTerm,
      keyboardFocus,
      activeDescendantRef,
      close
    }
  }
})

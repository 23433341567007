const routeSubpath = '/route'

export const routeRoutes = [
  {
    path: '/routes-and-timetables',
    name: 'Routes',
    meta: { showFooter: true },
    component: () =>
      import(/* webpackChunkName: "Routes" */ '../views/routes/Routes.vue')
  },
  {
    path: `${routeSubpath}/:routeNumber/:routeName/:forDate`,
    name: 'Route',
    props: true,
    meta: { showFooter: true },
    component: () =>
      import(/* webpackChunkName: "Routes" */ '../views/routes/Route.vue')
  }
]

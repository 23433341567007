import { httpClient } from './httpClient'
import Basket from '@/models/Basket'
import { Trip, TripResponse } from '@/models/Trip'
import { AxiosResponse } from 'axios'
import { TravelPassPayload } from '@/models/TravelPass'
import { Addons } from '@/models/Addon'
import { BasketProduct } from '@/models/Journey'

interface JourneyBody {
  JourneyId: string;
  PriceClass: string;
}

interface TripBody {
  OutboundJourney: JourneyBody;
  InboundJourney?: JourneyBody;
}

interface Passenger {
  ProductId: string;
  PassengerFirstName: string;
  PassengerLastName: string;
  Email: string;
}

const route = '/baskets'

const tripToBody = ({ outboundJourney, inboundJourney }: Trip): TripBody => {
  if (!outboundJourney) {
    throw Error('Trip must have outbound journey to add to basket')
  }

  const body: TripBody = {
    OutboundJourney: {
      JourneyId: outboundJourney.journey.Id,
      PriceClass: outboundJourney.priceInfo.PriceClassName
    }
  }

  if (inboundJourney) {
    body.InboundJourney = {
      JourneyId: inboundJourney.journey.Id,
      PriceClass: inboundJourney.priceInfo.PriceClassName
    }
  }

  return body
}

const createBasket = (): Promise<Basket> =>
  httpClient.post<Basket>(route).then((response: AxiosResponse) => response.data)

const hookBasket = (basketId: string): Promise<Basket> =>
  httpClient.get<Basket>(`${route}/${basketId}`).then((response: AxiosResponse) => response.data)

const addTripToBasket = (basketId: string, trip: Trip) =>
  httpClient.put<Basket>(`${route}/${basketId}/add-journey`, tripToBody(trip))

const addTravelPassesToBasket = (basketId: string, travelPasses: TravelPassPayload[]) =>
  httpClient.post<Basket>(`${route}/${basketId}/travel-pass`, { TravelPasses: travelPasses })

const renewBasket = (basketId: string) =>
  httpClient.put(`${route}/${basketId}/renew`)

const payBasketWithZeroPayment = (basketId: string) =>
  httpClient.post(`${route}/${basketId}/pay-with-zero-payment`)

const getBasketProducts = (basketId: string) =>
  httpClient.get<BasketProduct>(`${route}/${basketId}/products`)

const getBasketTrip = (basketId: string) =>
  httpClient.get<TripResponse>(`${route}/${basketId}/trip`)

const addPassengerDetails = (basketId: string, passengers: Passenger[]) =>
  httpClient.put(`${route}/${basketId}/passengers`, { Passengers: passengers })

const deleteBasket = (basketId: string) =>
  httpClient.delete(`${route}/${basketId}`)

const downloadBasket = (basketId: string) =>
  httpClient.get(`${route}/${basketId}/tickets`, { responseType: 'text' })

const getAvailableAddons = (basketId: string) =>
  httpClient.get<Addons>(`${route}/${basketId}/extras`)

const setAddons = (basketId: string, addons: Addons) =>
  httpClient.post(`${route}/${basketId}/extras`, addons)

const getBuyerDetails = (basketId: string) =>
  httpClient.get<{ Email: string }>(`${route}/${basketId}/buyer-detail`)

const emailBasket = (basketId: string, email: string) =>
  httpClient.post(
    `/tickets/${basketId}/to-email`,
    { RecipientEmail: email }
  )

export {
  createBasket,
  hookBasket,
  addTripToBasket,
  renewBasket,
  getBasketProducts,
  addPassengerDetails,
  deleteBasket,
  downloadBasket,
  addTravelPassesToBasket,
  payBasketWithZeroPayment,
  getBuyerDetails,
  emailBasket,
  getAvailableAddons,
  setAddons,
  getBasketTrip
}

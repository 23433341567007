export const companyRoutes = [
  {
    path: '/contact-us',
    name: 'ContactUs',
    component: () =>
      import(/* webpackChunkName: "company" */ '@/views/ContactUs.vue')
  },
  {
    path: '/contact-us/success',
    name: 'ContactUsSuccess',
    component: () =>
      import(/* webpackChunkName: "company" */ '@/views/FormSubmitted.vue')
  }
]


import { computed, defineComponent } from 'vue'
import Header from '@/components/Header.vue'
import Footer from '@/components/Navbars/Footer.vue'
import Snackbar from '@/components/Snackbar.vue'
import useSnackbar from '@/composables/useSnackbar'
import '@/assets/styles/colors.scss'
import '@/assets/styles/typography.scss'
import '@/assets/styles/widgets.scss'
import '@/assets/styles/inputs.scss'
import '@/assets/styles/cms_content.scss'
import { version } from '../package.json'
import useTitle from '@/composables/useTitle'
import { useHead } from '@vueuse/head'
import StopSearch from '@/components/StopSearch.vue'
import { useRouter } from 'vue-router'
import useSwrv from 'swrv'
import { getAllBusStops } from '@/expressway-api/busStops.api'
import { BusStop } from '@/models/BusStop'

export default defineComponent({
  components: { Header, Footer, Snackbar, StopSearch },
  setup () {
    const router = useRouter()
    const { title } = useTitle()
    // eslint-disable-next-line max-len
    const content = 'Expressway, Bus Éireann’s premium coach service, provides direct and regular services on 19 routes throughout Ireland.'
    useHead({
      meta: [
        {
          name: 'og:description',
          content: content,
          key: 'ogDescription'
        },
        {
          name: 'og:title',
          content: 'Expressway - Way Better',
          key: 'ogTitle'
        },
        {
          name: 'og:url',
          content: process.env.VUE_APP_SITE_URL,
          key: 'ogUrl'
        },
        {
          name: 'og:image',
          itemprop: 'image',
          content: `${process.env.VUE_APP_SITE_URL}images/expressway-image.jpeg`,
          key: 'ogImage'
        },
        {
          name: 'og:type',
          content: 'website',
          key: 'ogType'
        },
        {
          name: 'og:locale',
          content: 'en_IE',
          key: 'ogLocale'
        }
      ]
    })
    const { data: stops, error } = useSwrv(
      'busStops',
      getAllBusStops,
      {
        revalidateOnFocus: false,
        refreshInterval: 0
      }
    )

    const onSelect = (destination: BusStop) => {
      router.push({
        name: 'Journey Search',
        params:
          { props: JSON.stringify({ destination }) }
      })
    }

    const showStopSearch = computed(() => {
      if (router.currentRoute.value.name === 'Home') {
        return true
      }
      return false
    })
    return {
      ...useSnackbar(),
      version,
      title,
      onSelect,
      showStopSearch,
      stops,
      error
    }
  }
})
